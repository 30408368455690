import PropTypes from 'prop-types';
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import empPlan from "../../../assets/Employees.svg";
import ArrowRight from "../../../assets/Icon_Arrow_right.svg";
import "../../../css/EmpLogin.css";
import moment from 'moment';
import { asiaHongKong, DD_MM_YYYY_HH_mm_ss, enGB } from '../../../translations/common/commonConstants';

const ErAgentsOtherInd = () => {
  const { t } = useTranslation("common");
  const currentDateHk = new Date();
    const hkTimeString = currentDateHk.toLocaleString(enGB, { timeZone: asiaHongKong });
    const hkTime = moment(hkTimeString, DD_MM_YYYY_HH_mm_ss, true);
    // eslint-disable-next-line no-undef
    const cobrandTerminationDate = moment(process.env.REACT_APP_COBRAND_TERMINATION_DATE, DD_MM_YYYY_HH_mm_ss, true);

  const onClickLinkOpen = () => {
    document.getElementById('mpf-other-login').click();
  }

  return (
    <React.Fragment>
      <div className="login-card mpf-other-card text-center" onClick={onClickLinkOpen} id="er-agent">
        <img src={empPlan} className="empPlan" />
        <div className="row">
          <div className="col-12">
            <a href={t("links.otherMemberType")} className="login-type-cards-title" name="goto">
              {hkTime.toDate() > cobrandTerminationDate?.toDate() ?
              t("titles.ErAgentsNew") : t("titles.ErAgentsOld")}
              <img src={ArrowRight} alt="" className="gotoArrow" />
            </a>
            <p className="lt-title-transparent">
              {hkTime.toDate() > cobrandTerminationDate?.toDate() ? 
              t("titles.mpfEmployeeNew").substring(t("titles.ErAgentsNew").length + 3) : t("titles.mpfEmployeeOld").substring(t("titles.ErAgentsOld").length + 3)}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 lt-subtext">
            {hkTime.toDate() > cobrandTerminationDate?.toDate() ? 
            t("ErAgents.ErAgentsTextNew") : t("ErAgents.ErAgentsTextOld")}
          </div>
        </div>
        <a id="mpf-other-login" href={t("links.otherMemberType")}></a>
      </div>
    </React.Fragment>
  );
};

ErAgentsOtherInd.propTypes = {
  props: PropTypes.any,
  dispatch: PropTypes.func
}

const ErAgentsOtherIndComponent = connect()(ErAgentsOtherInd);
export default ErAgentsOtherIndComponent;